import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Angel = () => {
  return (
    <Fragment>
      <div id='angel'>
        <div className='intro-guide'>
          <div className='home-link'>
            <span>
              <Link to='/'>Home</Link>
            </span>
          </div>
        </div>
        <div className='guide-content'>
          <div className='guide-body-container'>
            <div className='guide-section-wrapper'>
              <h2 className='guide-section-title'>
                <span role='img' aria-label='angel emoji'>
                  🚀
                </span>{' '}
                Portfolio
              </h2>
              <p className='guide-paragraph'>
                I am occasionally fortunate to be able to support founders on
                their journey. Below are some of the startups I've invested in,
                either through my personal angel fund, private syndicate, or as
                a scout on behalf of Bain Capital Ventures.
              </p>
              <div className='table'>
                <table className='table-container'>
                  <thead>
                    <tr className='table-row-header'>
                      <th style={{ width: '18%' }}>Company</th>
                      <th style={{ width: '10%' }}>Industry</th>
                      <th style={{ width: '35%' }}>Description</th>
                      {/* <th style={{ width: '15%' }}>Entry</th> */}
                      <th style={{ width: '25%' }}>Other investors</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a
                          href='https://www.atomic.industries/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Atomic Industries
                        </a>
                      </td>
                      <td>Manufacturing</td>
                      <td>Automated tool and die-making</td>
                      <td>Narya, 8VC</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://varda.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Varda Space
                        </a>
                      </td>
                      <td>Space</td>
                      <td>Microgravity manufacturing platform</td>
                      <td>Founders Fund</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.causal.app/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Causal
                        </a>
                      </td>
                      <td>Enterprise SaaS</td>
                      <td>Next-gen tools for FP&A</td>
                      {/* <td>Series B</td> */}
                      <td>Accel, Coatue</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.joinpropel.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Propel
                        </a>
                      </td>
                      <td>Fintech</td>
                      <td>Financial OS for low-income Americans</td>
                      {/* <td>Series B</td> */}
                      <td>a16z, Kleiner Perkins</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.truenorthfleet.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Magic
                        </a>
                      </td>
                      <td>Gig economy</td>
                      <td>On-demand SMS personal assistant</td>
                      {/* <td>Series A</td> */}
                      <td>Sequoia</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.jerichosecurity.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Jericho Security
                        </a>
                      </td>
                      <td>Cybersecurity</td>
                      <td>Defense against generative AI attacks</td>
                      <td>Lux Capital, Era</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://holocron.tech/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Holocron
                        </a>
                      </td>
                      <td>Defense</td>
                      <td>Global technology awareness</td>
                      <td>New North Ventures</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='http://www.turing.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Turing
                        </a>
                      </td>
                      <td>Remote work</td>
                      <td>Easily access top 1% dev talent</td>
                      {/* <td>Series B</td> */}
                      <td>Founders Fund, Foundation Capital</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='http://finnihealth.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Finni Health
                        </a>
                      </td>
                      <td>Healthcare</td>
                      <td>Infra for autism care providers</td>
                      <td>General Catalyst</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.gptzero.me/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          GPTZero
                        </a>
                      </td>
                      <td>Edtech</td>
                      <td>The anti-GPT</td>
                      <td>Uncork Capital</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://wagr.us/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Wagr
                        </a>{' '}
                        (acquired)
                      </td>
                      <td>Entertainment</td>
                      <td>First social sports betting app</td>
                      {/* <td>Series A</td> */}
                      <td>776 Ventures, Pear Ventures</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://sivo.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Sivo
                        </a>
                      </td>
                      <td>Fintech</td>
                      <td>Embedded lending</td>
                      {/* <td>Seed+</td> */}
                      <td>Maple VC, A Typical VC</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.chiper.co/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Chiper
                        </a>
                      </td>
                      <td>E-commerce</td>
                      <td>Alibaba for Latam</td>
                      <td>JAM Fund</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.elysiumhealth.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Elysium
                        </a>
                      </td>
                      <td>Consumer biotech</td>
                      <td>NAD+ supplements</td>
                      {/* <td>Series C</td> */}
                      <td>General Catalyst, Breyer Capital</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://www.truenorthfleet.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          True North
                        </a>
                      </td>
                      <td>Transportation & Logistics</td>
                      <td>Full-stack freight carrier</td>
                      {/* <td>Series B</td> */}
                      <td>Sam Altman, Lachy Groom</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://infobot.ai/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Infobot
                        </a>
                      </td>
                      <td>Consumer Media</td>
                      <td>AI-generated news</td>
                      <td>Y Combinator, Rebel Fund</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://hellosalted.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Salted
                        </a>
                      </td>
                      <td>Food & Beverage</td>
                      <td>Virtual restaurant platform</td>
                      {/* <td>Series A</td> */}
                      <td>Craft Ventures, Creadev</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='https://pathao.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Pathao
                        </a>
                      </td>
                      <td>E-commerce & Fintech </td>
                      <td>Superapp for Southeast Asia </td>
                      <td>Anchorless Partners, Gojek Corporate</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='http://www.pallet.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Pallet
                        </a>
                      </td>
                      <td>Recruiting</td>
                      <td>Embedded recruiting infrastructure</td>
                      {/* <td>Seed</td> */}
                      <td>Backend Capital, Lenny Rachitsky</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href='http://ekhodealer.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Ekho Dealer
                        </a>
                      </td>
                      <td>E-commerce</td>
                      <td>Enabling D2C for OEMs</td>
                      <td>Riverpark Capital, Westcott Investment Group</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Angel;
